@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

html {
  background-color: black;
}

.body {
  background-color: black;
  color: green;
  font-family: 'Press Start 2P';
  font-size:smaller;
}



.button {
  font-family: 'Orbitron';
  color: green;
  background-color: black;
  width: 120px;
}

.button:hover {
  cursor: pointer;
}

.copy-icon {
  margin-left: 5px;
}

.copy-icon:hover {
  cursor: pointer;
}

.copy-icon:active {
  color: lightgreen;
  transform: translateY(4px);
}

.header {
  grid-area: header;
  display: grid;
  justify-content: center;
}

.message-box {
  display: grid;
  grid-area: messageBox;
  justify-content: center;
  border: 2px dashed green;
  margin: 10px 30% 10px 10%;

}


.binList {
  grid-area: bins;
  margin-right: 5px;
}

.unorderedList {
  padding-left: 0;
  margin-left: 0;
  padding-inline-start: 0;
}

.bin {
  list-style-type: none;
  white-space: nowrap;
}

.request {
  list-style-type: none;
  border: 2px solid green;
  margin-bottom: 2px;
}

.requests {
  grid-column-start: 2;
  grid-row-start: 1;
}

.binSection {
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "header header"
    "messageBox messageBox"
    "bins requests"
    "footer footer";
}

.footer {
  display: grid;
  justify-content: center;
  border: 2px dashed green;
  margin: 10px 10% 10px 10%;
}